exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-krakow-js": () => import("./../../../src/pages/krakow.js" /* webpackChunkName: "component---src-pages-krakow-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-o-szkole-js": () => import("./../../../src/pages/o-szkole.js" /* webpackChunkName: "component---src-pages-o-szkole-js" */),
  "component---src-pages-polityka-js": () => import("./../../../src/pages/polityka.js" /* webpackChunkName: "component---src-pages-polityka-js" */),
  "component---src-pages-rzeszow-js": () => import("./../../../src/pages/rzeszow.js" /* webpackChunkName: "component---src-pages-rzeszow-js" */),
  "component---src-pages-warszawa-js": () => import("./../../../src/pages/warszawa.js" /* webpackChunkName: "component---src-pages-warszawa-js" */),
  "component---src-pages-wybierz-miasto-js": () => import("./../../../src/pages/wybierz-miasto.js" /* webpackChunkName: "component---src-pages-wybierz-miasto-js" */)
}

